import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import StepsToStart from "../../sections/StepsToStart";
import OurLocations from "../../sections/OurLocations";
import { gaEvent } from "../../helpers";
import { useModal } from "../../../provider";

const stepsToStartId = "steps";
const membershipOptionsId = "membership-options";

const heroHeaderQuery = graphql`
    query {
        image: file(relativePath: { eq: "getting-started-banner.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
        }
    }
`;

const GettingStartedPage = () => {
    const headerImage = useStaticQuery(heroHeaderQuery);
    const modal = useModal();
    return (
        <Layout>
            <Seo
                title="Getting Started with FEAT Fitness"
                meta_title="Getting Started with FEAT Fitness"
                meta_desc="Are you about to start or looking to start with FEAT Fitness, see what to expect on your first day with FEAT, and what your training options are with us."
                slug="getting-started"
            />
            <main>
                <div>
                    {/* Hero card */}
                    <HeroHeader
                        title={"Welcome to FEAT Fitness"}
                        background_image={headerImage?.image}
                        options={[
                            {
                                title: "First day of Training?",
                                to: `#${stepsToStartId}`,
                            },
                            {
                                title: "Membership Options",
                                to: `#${membershipOptionsId}`,
                            },
                            {
                                title: "Free Trial",
                                highlighted: true,
                                onClick: () => {
                                    gaEvent(
                                        "Button",
                                        "FreeTrial",
                                        "free trial - hero header getting started"
                                    );
                                    modal.openFreeTrialModal();
                                },
                            },
                        ]}
                        hideShading
                    />
                    {/* End hero */}

                    {/* Training with FEAT Fitness */}
                    <StepsToStart id={stepsToStartId} />
                    {/* END Training with FEAT Fitness */}

                    {/* Map and Location Details */}
                    <OurLocations />
                    {/* END Map and Location Details */}

                    {/* Instagram Feed */}
                    <InstagramFeed />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
};

export default GettingStartedPage;
